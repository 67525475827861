
    .cart-main {
        position: relative;
    }

    .cart-bar {
        left: -450px;
        width: 450px;
    }

    .uk-open > .cart-bar {
        left: 0;
    }

    .cart-main > .cart-empty-text {
       color: var(--global-color);
   }

    .cart-title {
        position: relative;
        padding-top: calc(var(--global-small-gutter)*0.2);
        padding-right: calc(var(--global-small-gutter)*0.8);
        padding-bottom:calc(var(--global-small-gutter)*0.6);
        padding-left: calc(var(--global-small-gutter)*0.8);
    }

    .cart-proceed-wrapper {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 10;
        width: 100%;
        overflow: hidden;
        background: var(--global-secondary-background);
        padding: var(--global-small-gutter);
    }

    .cart-proceed {
        overflow: hidden;
        background: var(--global-primary-background);
        color: var(--global-background);
        transition: all 250ms;

        &:hover {
            background: var(--global-background);
            color: var(--global-primary-background);
        }
    }

    .cart-close {
        background: var(--global-secondary-background);
        color: var(--global-background);
        top: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        z-index: 2;
        width: 48px;
        height: 100%;
        cursor: pointer;
    }

    .cart-bar  .cart-title  * {
        color: var(--global-background);
    }

    .uk-offcanvas .cart-proceed-wrapper {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;

        .uk-button {
            &:hover {
                background-color: var(--global-background)!important;
                color: var(--global-primary-background)!important;
            }
        }

    }
